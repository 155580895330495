<template>
  <div class="main-content">
    <TableComponent
        section="transaction.Transaction"
        sectionRoot="transaction_list"
        moduleName="Transaction"
    ></TableComponent>
  </div>
</template>

<script>
import TableComponent from '@/components/common/other/table/TableComponent';

export default {
  name: 'Table',
  components: {
    TableComponent
  }
};
</script>
